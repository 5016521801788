import { PropertyHead } from 'recoil/properties'

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const numberFormatter = new Intl.NumberFormat('en-US')

// Money should never round. We use it for exact amounts (report prices)
export const formatMoney = (val: number | string | null): string => {
  if (val === null) return '$??.??'

  return currencyFormatter.format(Number(val))
}

export const formatMoneyNoDecimals = (val: number | string | null): string => {
  return formatMoney(val).replace(/.[\d]{2}$/, '')
}

export function formatCurrency(input: string | number, long?: boolean): string {
  const num = Number(input)

  // 995,000 and up, round to 10,000's place.
  let rNum = round(num, 10000)
  if (Math.abs(rNum) >= 1000000) {
    if (long) return formatMoneyNoDecimals(rNum)
    return `$${(rNum / 1000000).toFixed(2)}M`
  }
  // 4,950 and up, round to 1000's place
  rNum = round(num, 100) // Round to 100 to match boundary on next condition
  if (Math.abs(rNum) >= 5000) {
    rNum = round(num, 1000) // But round to 1000 for display
    if (long) return formatMoneyNoDecimals(rNum)
    return `$${rNum / 1000}k`
  }
  // 495 and up, round to 100's place.
  rNum = round(num, 10) // Round to 10 to match boundary on next condition
  if (Math.abs(rNum) >= 500) {
    rNum = round(num, 100) // But round to 100 for display
    if (long) return formatMoneyNoDecimals(rNum)
    return `$${rNum}`
  }
  // Round to 10s place.
  rNum = round(num, 10)
  return `$${rNum}`
}

// deprecated
export function formatCurrencyShort(input: string | number): string {
  return formatCurrency(input)
}

// deprecated
export function formatCurrencyLong(input: string | number): string {
  return formatCurrency(input)
}

export function getInitials(fullName: string): string {
  const parts = fullName.split(' ').filter((x) => Boolean(x))

  if (parts.length == 2) {
    return parts[0].charAt(0) + parts[1].charAt(0)
  }

  return parts.length == 1 ? parts[0].charAt(0) : ''
}

export function round(num, roundTo) {
  return Math.round(num / roundTo) * roundTo
}

export function formatNumber(val: number): string {
  return numberFormatter.format(val)
}

export function formatPercentage(input: string | number, decimals = 2): string {
  return `${Number(input).toFixed(decimals)}%`
}

export function numberWithCommas(input: number): string {
  return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function formatPropertyAddress(property: PropertyHead): string {
  return [
    property.delivery_line_1,
    property.delivery_line_2,
    property.city,
    property.state,
    property.zip9 ?? property.zip5,
  ]
    .filter((el) => el != null)
    .join(', ')
}

export function formatPublicPropertyAddress(publicProperty: AttomTaxAssessor): string {
  return [
    formatAddressCasing(publicProperty.propertyaddressfull),
    titleCase(publicProperty.propertyaddresscity),
    publicProperty.propertyaddressstate.toUpperCase(),
    publicProperty.propertyaddresszip,
  ]
    .filter((el) => el && el.length > 0)
    .join(', ')
}

export function formatPublicPropertyAddress2nd(publicProperty: AttomTaxAssessor): string {
  return [
    titleCase(publicProperty.propertyaddresscity),
    publicProperty.propertyaddressstate.toUpperCase(),
    publicProperty.propertyaddresszip,
  ]
    .filter((el) => el != null)
    .join(', ')
}

export function formatPublicPropertyAddressPrediction(publicProperty: AttomTaxAssessor): string {
  const addr = formatAddressCasing(publicProperty.propertyaddressfull)
  const city = titleCase(publicProperty.propertyaddresscity)
  const state = publicProperty.propertyaddressstate.toUpperCase()
  return `${addr}, ${city}, ${state}, USA`
}

export function formatOrdinal(value: string | number): string {
  let suffix = 'th'

  if (Number(value) < 4 || Number(value) > 13) {
    const string = String(value)
    const char = string.charAt(string.length - 1)
    suffix = char === '1' ? 'st' : char === '2' ? 'nd' : char === '3' ? 'rd' : 'th'
  }

  return `${value}${suffix}`
}

export function formatAddressCasing(address: string): string {
  return titleCase(address)
}

export function squareFeetInAcres(squareFeet: number): string {
  return (squareFeet / 43560).toFixed(2)
}

export function formatLotSize(lotSizeInFeet: number): string {
  if (lotSizeInFeet / 43560 < 0.2) {
    return `${numberWithCommas(lotSizeInFeet)} sqft`
  } else {
    return `${squareFeetInAcres(lotSizeInFeet)} acres`
  }
}

export function titleCase(str: string): string {
  if (!str) return ''

  return str.replace(/\w\S*/g, capitalize)
}

export function capitalize(txt: string): string {
  return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
}

export function capitalizeFirst(txt: string): string {
  return txt.charAt(0).toUpperCase() + txt.slice(1)
}

export function formatPhoneNumber(phone: string): string {
  if (!phone) return ''

  const match = /([+]?1)?\s*[(]?(?<area>\d{3})?[)]?\s*(?<prefix>\d{3})\s*[-]?\s*(?<line>\d{4})/.exec(phone)

  if (match && match.groups) {
    const area: undefined | string = match.groups['area']
    const prefix: string = match.groups['prefix']
    const line: string = match.groups['line']

    const formatted = `${prefix}-${line}`

    if (area) return `(${area}) ${formatted}`
    return formatted
  }

  return phone
}

export function humanize(str: string): string {
  return str.replace(/_/g, ' ').replace(/^./, (match) => match.toUpperCase())
}

export function truncate(text, length = 12, separator = '…', frontChars = 6, backChars = 5) {
  if (text.length <= length) return text

  return text.substr(0, frontChars) + separator + text.substr(text.length - backChars)
}
